<template>
  <div class="stats" v-if="hasPerm('payments.view_sale')">
    <page-header title="État des ventes" icon="fas fa-chart-pie" :links="getLinks()"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName)">
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Année"
            label-for="school-year"
            description=""
          >
            <b-select
              id="period"
              v-model="selectedSchoolYear"
              :disabled="schoolYears.length === 1"
            >
              <b-select-option v-for="schoolYear of schoolYears" :key="schoolYear.id" :value="schoolYear">
                {{ schoolYear.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Mode"
            label-for="mode"
            description=""
          >
            <b-select
              id="mode"
              v-model="selectedMode"
            >
              <b-select-option v-for="mode of modes" :key="mode.id" :value="mode">
                {{ mode.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Affichage"
            label-for="display"
            description=""
          >
            <b-form-checkbox
              id="show-day-times"
              v-model="showDetail"
              name="display"
            >
              Affiche les pourcentages et nombres de ventes
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="filter-bar">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Compte analytique"
              label-for="analyticName"
              description="filtrer les analytiques"
            >
              <b-form-input
                type="text"
                id="analyticName"
                v-model="analyticName"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Affichage analytique"
              label-for="anaDisplay"
            >
              <b-select
                id="mode"
                v-model="anaDisplay"
                @change="refresh()"
              >
                <b-select-option v-for="elt of anaDisplays" :key="elt.id" :value="elt.id">
                  {{ elt.name }}
                </b-select-option>
              </b-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Données"
              label-for="data"
            >
              <b-select
                id="mode"
                v-model="selectedData"
                @change="refresh()"
              >
                <b-select-option v-for="elt of datas" :key="elt.id" :value="elt">
                  {{ elt.name }}
                </b-select-option>
              </b-select>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Filtre famille"
              label-for="entityId"
              description="seulement les ventes de la famille N°"
            >
              <b-form-input
                type="number"
                step="1"
                min="0"
                id="entityId"
                v-model="entityId"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="1">
            <div class="refresh-holder">
              <a class="btn btn-sm btn-secondary" href @click.prevent="refresh()">
                <i class="fa fa-refresh"></i>
              </a>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="selectedMode">
        <b-col>
          <div v-if="selectedModeCompat" class="compat-warning">
            Ce mode inclut les annulations non facturées pour compatibilité avec les versions précédentes
            (avant mai 2024)
          </div>
          <div v-else class="compat-info">
            Ce mode exclut les annulations non facturées contrairement aux versions précédentes
            (avant mai 2024)
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div ref="excelTable">
            <div ref="printMe">
              <table class="table table-striped table-bordered table-prices table-adapt">
                <tr>
                  <th>{{ selectedSchoolYear.name }}</th>
                  <th
                    v-for="theDate of months"
                    :key="theDate"
                    class="text-right"
                    :class="monthlySalesCount(theDate) === 0 ? 'empty-month' : ''"
                  >
                    {{ getMonthName(theDate )}}
                  </th>
                  <th>
                  </th>
                </tr>
                <tr v-for="analyticKey of analyticKeysWithSales" :key="analyticKey">
                  <th class="highlight analytic-label">
                    <b>{{ getAnalyticLabel(analyticKey) }}</b>
                  </th>
                  <td
                    v-for="theDate of months"
                    :key="theDate"
                    class="number"
                    :class="monthlySalesCount(theDate) === 0 ? 'empty-month' : ''"
                  >
                    <span v-if="getMonthSales(analyticKey, theDate)">
                      {{ getMonthPrice(analyticKey, theDate) | currency }}
                      <span v-if="showDetail">
                        <span class="percentage">{{ monthlyPercent(analyticKey, theDate) }}</span>
                        <div class="sep"></div>
                        <counter-label hide-zero :counter="getMonthSales(analyticKey, theDate, 1)" label="vente"></counter-label>
                        <counter-label hide-zero :counter="getMonthSales(analyticKey, theDate, 2)" label="annulation"></counter-label>
                        <span class="percentage">{{ monthlySalesPercent(analyticKey, theDate) }}</span>
                      </span>
                    </span>
                    <span v-else>&nbsp;</span>
                  </td>
                  <th class="highlight number">
                    {{ yearlyTotal(analyticKey) | currency }}
                    <span v-if="showDetail">
                      <span class="percentage">{{ yearlyPercent(analyticKey) }}</span>
                      <div class="sep"></div>
                      <counter-label :counter="yearlySales(analyticKey)" label="vente"></counter-label>
                      <span class="percentage">{{ yearlySalesPercent(analyticKey) }}</span>
                    </span>
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th
                    v-for="theDate of months"
                    :key="getMonthName(theDate)"
                    class="text-right"
                    :class="monthlySalesCount(theDate) === 0 ? 'empty-month' : ''"
                  >
                    {{ getMonthName(theDate)}}
                  </th>
                  <th>
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th
                    v-for="theDate of months"
                    :key="theDate"
                    class="number"
                    :class="monthlySalesCount(theDate) === 0 ? 'empty-month' : ''"
                  >
                    {{ monthlyTotal(theDate) | currency }}
                    <span v-if="showDetail">
                      <div class="sep"></div>
                      <counter-label :counter="monthlySalesCount(theDate)" label="vente"></counter-label>
                    </span>
                  </th>
                  <th class="number">
                    {{ totalAmount | currency }}
                    <span v-if="showDetail">
                      <div class="sep"></div>
                      <counter-label :counter="totalSales" label="vente"></counter-label>
                    </span>
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeChoice } from '@/types/base'
import { makeAnalyticAccount } from '@/types/payments'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import { percentage } from '@/utils/math'

export default {
  name: 'stats-monthly-sales',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    PageHeader,
    LoadingGif,
  },
  data() {
    return {
      loadingName: 'monthly-sales',
      monthlySales: new Map(),
      monthlyMap: new Map(),
      analyticNamesMap: new Map(),
      analytics: [],
      schoolYears: [],
      analyticSchoolYears: [],
      selectedSchoolYear: null,
      totalSales: 0,
      totalAmount: 0,
      startDate: null,
      endDate: null,
      selectedMode: null,
      showDetail: false,
      analyticName: '',
      selectedData: null,
      entityId: null,
      anaDisplay: 0,
    }
  },
  computed: {
    modes() {
      return [
        makeChoice({ id: 0, name: 'Ventes de l\'année scolaire', }),
        makeChoice({ id: 1, name: 'Ventes pendant l\'année scolaire', }),
        makeChoice({ id: 2, name: 'Ventes de l\'année scolaire (compatibilité)', }),
        makeChoice({ id: 3, name: 'Ventes pendant l\'année scolaire (compatibilité)', })
      ]
    },
    datas() {
      return [
        makeChoice({ id: 0, name: 'Ventes et annulations', }),
        makeChoice({ id: 1, name: 'Ventes seules', }),
        makeChoice({ id: 2, name: 'Annulations seules', })
      ]
    },
    anaDisplays() {
      return [
        makeChoice({ id: 0, name: 'Nom', }),
        makeChoice({ id: 1, name: 'Code', })
      ]
    },
    months() {
      const months = []
      let loopDate = this.startDate
      if (loopDate != null) {
        while (loopDate <= this.endDate) {
          const monthlyKey = '' + loopDate.year() + '-' + (loopDate.month() + 1)
          months.push(monthlyKey)
          loopDate = loopDate.add('month', 1).clone()
        }
      }
      return months
    },
    analyticKeysWithSales() {
      const analyticKeys = []
      const analyticName = this.analyticName.toUpperCase()
      for (const schoolYear of this.analyticSchoolYears) {
        for (const analytic of this.analytics) {
          let show = true
          if (analyticName && analytic.name.toUpperCase().indexOf(analyticName) < 0) {
            show = false
          }
          if (show) {
            const key = '' + analytic.id + '#' + schoolYear
            if (this.hasSales(key)) {
              analyticKeys.push(key)
            }
          }
        }
      }
      return analyticKeys
    },
    selectedModeCompat() {
      if (this.selectedMode) {
        return (this.selectedMode.id === 2) || (this.selectedMode.id === 3)
      }
      return false
    },
  },
  watch: {
    selectedSchoolYear: function() {
      const year = '' + this.selectedSchoolYear.startYear
      if (this.$route.query.year !== year) {
        router.push({ path: this.$route.path, query: { year: year, mode: '' + this.selectedMode.id, }, })
      }
      this.loadMonthlySales()
    },
    selectedMode: function() {
      if (this.selectedSchoolYear) {
        const mode = '' + this.selectedMode.id
        if (this.$route.query.mode !== mode) {
          router.push({ path: this.$route.path, query: { year: '' + this.selectedSchoolYear.startYear, mode: mode, }, })
        }
        this.loadMonthlySales()
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadMonthlySales() {
      this.startLoading(this.loadingName)
      const monthlySales = new Map()
      this.totalSales = 0
      this.totalAmount = 0
      this.startDate = null
      this.endDate = null
      this.monthlyMap.clear()
      this.analyticSchoolYears = []
      let analyticSchoolYears = []
      try {
        let url = '/api/monthly-sales/' + this.selectedSchoolYear.id + '/'
        url += '?mode=' + this.selectedMode.id
        if (this.selectedData.id) {
          url += '&data=' + this.selectedData.id
        }
        if (this.entityId) {
          url += '&entity=' + this.entityId
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        let data = resp.data
        for (const elt of data) {
          const month = +elt['created_on__month']
          const year = +elt['created_on__year']
          const schoolYear = +elt['school_year__start_year']
          if (analyticSchoolYears.indexOf(schoolYear) < 0) {
            analyticSchoolYears.push(schoolYear)
          }
          const theDate = moment({ year: year, month: month - 1, day: 1, })
          if (this.startDate === null) {
            this.startDate = theDate.clone()
            this.endDate = theDate.clone()
          }
          if (theDate < this.startDate) {
            this.startDate = theDate.clone()
          }
          if (theDate > this.endDate) {
            this.endDate = theDate.clone()
          }
          const monthlyKey = '' + year + '-' + month
          const analyticId = +elt['analytic_account']
          const analyticKey = '' + analyticId + '#' + schoolYear
          const total = +elt['total']
          const number = +elt['number']
          const cancellationsNumber = +elt['cancellations_number']
          let map = monthlySales.get(analyticKey) || null
          if (map === null) {
            map = new Map()
          }
          this.totalSales += number
          this.totalAmount += total
          let monthTotal = this.monthlyMap.get(monthlyKey) || null
          if (monthTotal === null) {
            monthTotal = [0, 0, 0]
          }
          monthTotal = [monthTotal[0] + total, monthTotal[1] + number, monthTotal[2] + cancellationsNumber]
          this.monthlyMap.set(monthlyKey, monthTotal)
          map.set(monthlyKey, [total, number, cancellationsNumber])
          monthlySales.set(analyticKey, map)
          this.analyticSchoolYears = analyticSchoolYears
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.monthlySales = monthlySales
      this.endLoading(this.loadingName)
    },
    async loadAnalytics() {
      let url = '/api/analytic-accounts/?all=1'
      const backendApi = new BackendApi('get', url)
      this.analyticNamesMap.clear()
      try {
        const resp = await backendApi.callApi()
        this.analytics = [makeAnalyticAccount({ id: 0, name: 'sans code', })].concat(
          resp.data.map(elt => makeAnalyticAccount(elt))
        )
        for (const analytic of this.analytics) {
          const name = this.anaDisplay ? analytic.getCode() : analytic.getLabel()
          this.analyticNamesMap.set(analytic.id, name)
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
        if (this.schoolYears.length) {
          let schoolYear = (this.$route.query ? this.$route.query.year : '')
          if (schoolYear) {
            schoolYear = +schoolYear
          }
          let schoolYearIndex = 0
          if (!isNaN(schoolYear)) {
            schoolYearIndex = this.schoolYears.map(elt => elt.startYear).indexOf(schoolYear)
            if (schoolYearIndex < 0) {
              schoolYearIndex = 0
            }
          }
          this.selectedSchoolYear = this.schoolYears[schoolYearIndex]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadAll() {
      this.startLoading(this.loadingName)
      let mode = (this.$route.query ? this.$route.query.mode : '')
      if (mode) {
        mode = +mode
      }
      let modeIndex = 0
      if (!isNaN(mode)) {
        modeIndex = this.modes.map(elt => elt.id).indexOf(mode)
        if (modeIndex < 0) {
          modeIndex = 0
        }
      }
      this.selectedMode = this.modes[modeIndex]
      await this.loadSchoolYears()
      await this.loadAnalytics()
      this.endLoading(this.loadingName)
    },
    getMonthName(monthlyKey) {
      const elements = monthlyKey.split('-').map(elt => +elt)
      return moment({ year: elements[0], month: elements[1] - 1, day: 1, }).format('MMM YYYY')
    },
    getMonthPrice(analyticKey, month) {
      const map = this.monthlySales.get(analyticKey)
      if (map) {
        const array = map.get(month) || null
        if (array !== null) {
          return array[0]
        }
      }
      return ''
    },
    getMonthSales(analyticKey, month, index = 0) {
      const map = this.monthlySales.get(analyticKey)
      if (map) {
        const array = map.get(month) || null
        if (array !== null) {
          if (index) {
            return array[index]
          } else {
            return array[1] + array[2]
          }
        }
      }
      return 0
    },
    hasSales(analyticKey) {
      const map = this.monthlySales.get(analyticKey)
      if (map) {
        for (const value of map.values()) {
          if (value && value[0]) {
            return true
          }
        }
      }
      return false
    },
    getAnalyticLabel(analyticKey) {
      const tokens = analyticKey.split('#')
      const analyticId = +tokens[0]
      const schoolYear = +tokens[1]
      let analyticLabel = this.analyticNamesMap.get(analyticId)
      if (this.analyticSchoolYears.length > 1) {
        analyticLabel += ' - ' + schoolYear + '/' + (schoolYear + 1)
      }
      return analyticLabel
    },
    yearlyTotal(analyticKey) {
      let total = 0
      const map = this.monthlySales.get(analyticKey)
      if (map) {
        for (const value of map.values()) {
          if (value) {
            total += value[0]
          }
        }
      }
      return total
    },
    yearlySales(analyticKey) {
      let total = 0
      const map = this.monthlySales.get(analyticKey)
      if (map) {
        for (const value of map.values()) {
          if (value) {
            total += value[1]
          }
        }
      }
      return total
    },
    yearlyPercent(analyticKey) {
      return percentage(this.yearlyTotal(analyticKey), this.totalAmount)
    },
    yearlySalesPercent(analyticKey) {
      return percentage(this.yearlySales(analyticKey), this.totalSales)
    },
    monthlyTotal(monthlyKey) {
      const monthTotal = this.monthlyMap.get(monthlyKey) || null
      if (monthTotal !== null) {
        return monthTotal[0]
      }
      return 0
    },
    monthlySalesCount(monthlyKey) {
      const monthTotal = this.monthlyMap.get(monthlyKey) || null
      if (monthTotal !== null) {
        return monthTotal[1] + monthTotal[2] // inclure les annulations dans les ventes
      }
      return 0
    },
    monthlyPercent(analyticKey, month) {
      return percentage(this.getMonthPrice(analyticKey, month), this.monthlyTotal(month))
    },
    monthlySalesPercent(analyticKey, month) {
      return percentage(this.getMonthSales(analyticKey, month), this.monthlySalesCount(month))
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      if (this.selectedSchoolYear) {
        const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
        const docSlug = 'monthly-sales-' + this.selectedSchoolYear.id
        const docContent = this.$refs.printMe.innerHTML.toString()
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'monthly-sales-' + this.selectedSchoolYear.id
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async refresh() {
      await this.loadAnalytics()
      await this.loadMonthlySales()
    },
  },
  created() {
    this.selectedMode = this.modes[0]
    this.selectedData = this.datas[0]
    this.anaDisplay = this.anaDisplays[0].id
    this.loadAll()
  },
}
</script>

<style scoped lang="less">
.table-adapt {
  width: 100%;
  table-layout: fixed;
}
.table-prices td, .table-prices th {
  font-size: 11px;
  overflow: hidden;
}
.table-prices > tr > td.empty-month,
.table-prices > tr > th.empty-month{
  width: 1px !important;
  padding: 1px;
  background: #000 !important;
}
.highlight {
  background: #888;
  color: #fff;
}
.analytic-label {
  max-width: 90px;
  font-size: 10px;
}
table.table-striped tr:nth-of-type(2n+1) td.highlight {
  background: #777 !important;
  color: #fff;
}
.percentage {
  display: inline-block;
  font-size: 9px;
  width: 32px;
  padding: 2px;
  color: #fff;
  background: #333;
}
td .sep {
  margin-bottom: 1px;
}
td .counter-label .counter-label-text, th .counter-label .counter-label-text {
  font-size: 6px;
  margin-right: 1px;
}
.number {
  text-align: right;
}
.compat-warning, .compat-info {
  padding: 5px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}
.compat-warning {
  background: #ffb98a;
}
.compat-info {
  background: #cce5ff;
}
.filter-bar {
  padding: 5px;
  background: #eee;
  margin: 5px 0;
  font-size: 14px;

  select, input {
    font-size: 14px !important;
  }
  .refresh-holder {
    text-align: right;
    margin-top: 28px;
  }
}
</style>
